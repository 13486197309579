import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LiveView from './LiveView'

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const UNIT_PREFIX = process.env.REACT_APP_UNIT_PREFIX;

export default function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LiveView unit={UNIT_PREFIX}/>}/>
        </Routes>
      </BrowserRouter>
    );
  }

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

